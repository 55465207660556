<template>
  <div class="form">
    <div class="form-inner">
      <h2>注册您的 账号</h2>
      <div class="form-item">
        <el-input prefix-icon="el-icon-user" v-model="account" placeholder="账号" autocomplete="off"
          :input-style="inputStyle"></el-input>
      </div>
      <div class="form-item">
        <el-input prefix-icon="el-icon-lock" v-model="password" placeholder="请输入密码" autocomplete="new-password"
          type="password" :input-style="inputStyle" @keypress.native.enter="submitRegister">
        </el-input>
      </div>
      <div class="form-item">
        <el-input prefix-icon="el-icon-lock" v-model="repeatPassword" placeholder="再次输入密码" autocomplete="new-password"
          type="password" :input-style="inputStyle" @keypress.native.enter="submitRegister">
        </el-input>
      </div>
      <!-- <div class="form-item"> -->
      <el-button type="primary" class="btn" @click="submitRegister">注册
      </el-button>
      <!-- </div> -->
    </div>
  </div>
</template>
<script>
import { debounce } from "../../utils/index.js";
import { validPassword, validUsername } from "../../utils/validate.js";
export default {
  name: "UserRegister",
  props: {
    navigator: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      inputStyle: {},
      account: "",
      password: "",
      repeatPassword: "",
      success: false,
    };
  },
  methods: {
    submitRegister: debounce(
      function () {
        if (this.success) return;

        if (!validUsername(this.account)) {
          return this.$message.warning(
            "账号为以字母开头的8-18位的字母、数字、下划线组成"
          );
        }
        if (this.password != this.repeatPassword) {
          return this.$message.warning("两次密码输入不一致");
        }
        if (!validPassword(this.password) || !validPassword(this.repeatPassword)) {
          return this.$message.warning(
            "密码格式错误，密码为8-16位的数字、字母、下划线组成"
          );
        }

        this.$post("/users/register", {
          account: this.account,
          password: this.password,
          repeatPassword: this.repeatPassword,
        }).then((res) => {
          if (res.code != 200) {
            return this.$message.error(res.message);
          }

          this.success = true;

          if (this.navigator) {
            this.$message.success("注册成功，即将跳转登录界面...");
            setTimeout(() => {
              this.$router.push({
                path: "login",
                query: this.$route.query,
              });
            }, 1000);
          } else {
            this.$message.success("注册成功");
          }

          this.account = "";
          this.password = "";
          this.repeatPassword = "";
        });
      },
      1000,
      true
    ),
  },
};
</script>

<style lang="scss" scoped>
.form {
  width: 410px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  .form-inner {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    padding: 20px;
  }

  h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.1;
    margin: 5px 0 40px;
    color: #222;
  }

  .form-item {
    margin-bottom: 30px;
    border-radius: 4px;

    /deep/.el-input__inner {
      background-color: transparent;
      border-color: #eaeaea;
      color: #333;
    }
  }

  .btn {
    width: 100%;

    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
